import "./styles.css";
import { Image } from 'react-bootstrap';

function Shop () {
    return (
        <>
            <Image src="" fluid />
            <div>
                <h2>
                    Shop Coming Soon
                </h2>
                <p>
                    Nothing to see here ...yet!
                </p>
            </div>
        </>
    )
}

export default Shop;